export default {
  methods: {
    getQuarter(date) {
      date = this.checkAndParseStringToDate(date);
      if (date.getFullYear() == 1) {
        return null;
      }
      let month = date.getMonth() + 1;
      if (month >= 4 && month <= 6) {
        return { year: date.getFullYear(), quarter: 2 };
      } else if (month >= 7 && month <= 9) {
        return { year: date.getFullYear(), quarter: 3 };
      } else if (month >= 10 && month <= 12) {
        return { year: date.getFullYear(), quarter: 4 };
      } else {
        return { year: date.getFullYear(), quarter: 1 };
      }
    },
    getQuarterDate(quarter, year) {
      if (quarter == 1) {
        return new Date(Date.UTC(year, 2, 31));
      } else if (quarter == 2) {
        return new Date(Date.UTC(year, 5, 30));
      } else if (quarter == 3) {
        return new Date(Date.UTC(year, 8, 30));
      } else {
        return new Date(Date.UTC(year, 11, 31));
      }
    },
    checkAndParseStringToDate(date) {
      if (typeof date == "string") {
        date = new Date(Date.parse(date));
      }
      return date;
    },
    getPreviousQuarter(date) {
      date = this.checkAndParseStringToDate(date);
      var quarter = this.getQuarter(date).quarter;
      var year = date.getFullYear();

      if (quarter == 1) {
        year -= 1;
        quarter = 4;
      } else {
        quarter -= 1;
      }

      return { year: year, quarter: quarter };
    },
    // backend stores most dates without utc flag (Z), although they are UTC dates
    // some dates, however, come with the flag, so we need to "normalize" and remove it
    normalizeUtcDate(date) {
      if (date instanceof Date) {
        date = date.toISOString();
      }
      return date?.replace("Z", "");
    },
  },
};
